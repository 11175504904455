<template>
  <v-card>
    <dialog-bar @expand="$emit('expand', $event)"></dialog-bar>
    <v-card-text class="ma-0 pa-0">
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">取り込み入力</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="2">取り込み編集/確認</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="3">完了</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form style="height: 300px" ref="importForm" v-model="validImportForm" lazy-validation>
              <v-container class="pa-0">
                <v-row dense>
                  <v-col cols="12">
                    <v-file-input
                      label="CSVファイルをアップロードしてください"
                      v-model="file"
                      :rules="[rules.required, rules.isCsv]"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <simple-file-drop v-model="file" style="height: 145px"></simple-file-drop>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
            <v-divider class="my-3"></v-divider>
            <v-btn color="secondary" @click="onCancel">キャンセル</v-btn>
            <v-btn color="primary" class="float-right" @click="onImport()">取り込み</v-btn>
          </v-stepper-content>
          <v-stepper-content step="2">
            <div style="height: 300px">
              <ag-grid-vue
                :gridOptions="gridOptions"
                :rowData="csvRecords"
                class="ag-theme-alpine"
                style="height: 100%"
                :alwaysShowHorizontalScroll="true"
              ></ag-grid-vue>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-btn color="secondary" @click="onBack()">戻る</v-btn>
            <v-btn color="primary" class="float-right" @click="onSubmit()">確定</v-btn>
          </v-stepper-content>
          <v-stepper-content step="3">
            <div style="height: 300px">
              <p>{{ importCount }}件の取り込みが完了しました。 (一括取込番号：{{ importNumber }})</p>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-btn color="primary" class="float-right" @click="onComplete()">閉じる</v-btn></v-stepper-content
          >
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog ref="bulkErrorGrid" width="80%" height="80%" :columns="errorColmuns"></error-grid-dialog>
  </v-card>
</template>

<style>
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import Validation from "../../libs/validation";
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
} from "../../models/ag-grid/columnTypes";
import FileUtils from "../../utils/FileUtils";
import DialogBar from "../common/DialogBar.vue";
import SimpleFileDrop from "../common/SimpleFileDrop.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import StockOwners from "../../consts/StockOwners";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import RequestUtils from "../../utils/RequestUtils";

export default {
  name: "DetailImport",
  props: ["propositionModel"],
  components: {
    AgGridVue,
    DialogBar,
    SimpleFileDrop,
    ErrorGridDialog,
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  data: () => ({
    rules: {
      required: Validation.required,
      maxLength: Validation.maxLength,
      isDecimal: Validation.isDecimal,
      isNumber: Validation.isNumber,
      isJancode: Validation.isJancode,
      isMinNumber: Validation.isMinNumber,
      isRate: Validation.isRate,
      isTodayAfter: Validation.isTodayAfter,
      isCsv: Validation.isCsv,
      maxRateLength: Validation.maxRateLength,
    },
    step: 1,
    file: null,
    validImportForm: null,
    importCount: 0,
    importNumber: "",
    alreadyDeliveryData: [],
    alreadyCustomerData: [],
    gridOptions: {
      defaultColDef: {
        resizable: true,
        sortable: false,
        filter: false,
        editable: false,
      },
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
        dpDateColumn: DateColumn,
        dpFullDateColumn: FullDateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
      },
      columnDefs: [],
      rowSelection: false,
      suppressRowClickSelection: true,
      singleClickEdit: true,
      pagination: false,
      localeText: AG_GRID_LOCALE_JA,
    },
    csvRecords: [],
    cafereoColumns: [
      { headerName: "委託・消化仕入明細ID", field: "propositionDetailId" },
      { headerName: "委託・消化仕入ID", field: "propositionId" },
      { headerName: "案件名", field: "propositionName" },
      { headerName: "委託先店舗コード", field: "customerCode" },
      { headerName: "取引先名", field: "customerName" },
      { headerName: "委託JANCODE", field: "janCode" },
      { headerName: "委託タイトル", field: "title" },
      { headerName: "委託商品名", field: "productName" },
      { headerName: "委託数", field: "entrustQuantity", type: "dpNumericColumn" },
      { headerName: "卸掛率", field: "wholesaleRate", type: "dpPercentColumn" },
      { headerName: "仕入掛率", field: "purchaseRate", type: "dpPercentColumn" },
      { headerName: "配送先名", field: "deliveryCode" },
      { headerName: "直送依頼", field: "directDeliveryRequestFlg" },
      { headerName: "配送先〒", field: "deliveryPostalCode" },
      { headerName: "配送先住所", field: "deliveryAddress" },
      { headerName: "届先法人名", field: "deliveryName1" },
      { headerName: " 届先名称", field: "deliveryName2" },
      { headerName: "配送先TEL", field: "deliveryTelNumber" },
      { headerName: "在庫所有元", field: "stockOwner" },
      { headerName: "出荷予定日", field: "shipmentPlanDate" },
      { headerName: "返送先コード", field: "supplierDeliveryCode" },
      { headerName: "返送先〒", field: "supplierDeliveryPostalCode" },
      { headerName: "返送先住所", field: "supplierDeliveryAddress" },
      { headerName: "返送先届先法人名", field: "supplierDeliveryName1" },
      { headerName: "返送先届先名称", field: "supplierDeliveryName2" },
      { headerName: "返送先TEL", field: "supplierDeliveryTelNumber" },
    ],
    errorColmuns: [
      { headerName: "行", field: "indexNo" },
      {
        headerName: "エラー内容",
        field: "errorMessage",
        wrapText: true,
        autoHeight: true,
        cellRenderer: function (param) {
          return param.data.errorMessage.join("<br>");
        },
      },
    ],
  }),
  methods: {
    async onImport() {
      if (this.validate()) {
        try {
          this.loadingOn();
          const csvLines = await FileUtils.readAsCsv(this.file);
          this.csvRecords = [];
          console.log(csvLines);
          // csvLines.forEach((line, index) =>
          for (let index = 0; index < csvLines.length; index++) {
            let line = csvLines[index];
            if (index > 0 && line.length > 1) {
              const record = {
                propositionDetailId: line[0],
                propositionId: this.propositionModel.propositionId,
                propositionName: this.propositionModel.propositionName,
                customerCode: line[1],
                janCode: line[2],
                entrustQuantity: line[3],
                wholesaleRate: line[4],
                purchaseRate: line[5],
                deliveryCode: line[6],
                directDeliveryRequestFlg: line[7],
                stockOwner: line[8],
                shipmentPlanDate: line[9],
                supplierDeliveryCd: line[10],
                customerFlg: false,
                productFlg: false,
                deliveryFlg: false,
                supplierDeliveryFlg: false,
              };
              const customer = await this.getCustomerData(record.customerCode);
              console.log(customer);
              if (customer && customer.corporateCode == this.propositionModel.corporateCode) {
                record.customerFlg = true;
                record.customerName = customer.customerName;
              }

              const product = await this.getProductData(record.janCode);
              if (product) {
                console.log(product);
                record.productFlg = true;
                record.productCode = product.productCd;
                record.productName = product.productName;
                record.title = product.title;
              }

              const delivery = await this.getDeliveryData(record.deliveryCode);
              if (delivery) {
                record.deliveryFlg = true;
                record.deliveryPostalCode = delivery.deliveryZipCode;
                record.deliveryAddress = delivery.deliveryAddress;
                record.deliveryName1 = delivery.delivery1;
                record.deliveryName2 = delivery.delivery2;
                record.deliveryTelNumber = delivery.deliveryPhoneNumber;
              }

              if (Number(record.stockOwner) == StockOwners.CAFEREO) {
                record.supplierDeliveryFlg = true;
                record.supplierDeliveryCd = "";
              } else {
                const supplierDelivery = await this.getDeliveryData(record.supplierDeliveryCd);
                if (supplierDelivery) {
                  record.supplierDeliveryFlg = true;
                  record.supplierDeliveryPostalCode = supplierDelivery.deliveryZipCode;
                  record.supplierDeliveryAddress = supplierDelivery.deliveryAddress;
                  record.supplierDeliveryName1 = supplierDelivery.delivery1;
                  record.supplierDeliveryName2 = supplierDelivery.delivery2;
                  record.supplierDeliveryTelNumber = supplierDelivery.deliveryPhoneNumber;
                }
              }

              this.csvRecords.push(record);
            }
          }
          if (this.validateRecords(this.csvRecords)) {
            this.step++;
          }
        } catch (error) {
          this.$dialog.notify.error(`アップロードファイルの読み込みに失敗しました。`, { timeout: 2300 });
        } finally {
          await this.loadingOff();
        }
      }
    },
    validate() {
      const isValid = this.$refs.importForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      } else {
        this.$refs.importForm.resetValidation();
      }
      return isValid;
    },
    async onSubmit() {
      if (this.validateRow(this.csvRecords)) {
        try {
          this.loadingOn();
          let updateModels = [];
          this.csvRecords.forEach((row) => {
            console.log(row);
            updateModels.push({
              propositionDetailId: row.propositionDetailId,
              propositionId: row.propositionId,
              customerCode: row.customerCode,
              productCode: row.productCode,
              storeName: row.storeName,
              propositionJanCode: row.janCode,
              entrustQuantity: Number(row.entrustQuantity),
              wholesaleRate: parseFloat(row.wholesaleRate),
              purchaseRate: parseFloat(row.purchaseRate),
              deliveryCode: row.deliveryCode,
              directDeliveryRequestFlg: row.directDeliveryRequestFlg == "Y",
              stockOwner: row.stockOwner,
              shipmentPlanDate: row.shipmentPlanDate,
              supplierDeliveryCd: row.supplierDeliveryCd,
            });
          });
          // 最大件数チェック
          const validDataSizeRet = await RequestUtils.validDataSize("C020409", updateModels.length, (limitSize) => {
            this.$dialog.notify.error(`最大処理件数（${limitSize}件）オーバーのため処理出来ませんでした。`, {
              timeout: 2300,
            });
            this.loadingOff();
          });
          if (!validDataSizeRet) {
            return;
          }

          const result = await this.$store.dispatch("propositionDetail/import", {
            rows: updateModels,
            splitNum: 100,
          });

          let error = result.data?.header;

          const errorRows = [];
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  errorRows.push({
                    indexNo: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              if (errorRows.length > 0) {
                this.$refs.bulkErrorGrid.open({ title: "委託・消化仕入一括登録", records: errorRows });
                this.$dialog.notify.error(`委託・消化仕入一括登録に失敗したデータが存在します。ご確認ください。`, {
                  timeout: 2300,
                });
              }
              this.importCount = result.data?.contents.propositionDetail.processCount;
              this.importNumber = result.data?.contents.propositionDetail.importNumber;
              this.step++;
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("CorporationUpdate::onUpdateClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    validateRecords(records) {
      let isValid = true;
      if (records.length === 0) {
        this.$dialog.notify.error(`データがありません`, { timeout: 2300 });
        isValid = false;
      }
      return isValid;
    },
    validateRow(rows) {
      let isValid = true;
      let errorRows = [];
      rows.forEach((row, idx) => {
        var error = this.onValidGrid(row);
        if (error != true) {
          errorRows.push({
            indexNo: idx + 1,
            errorMessage: error,
          });
          isValid = false;
        }
      });
      if (!isValid) {
        this.$refs.bulkErrorGrid.open({ title: "委託・消化仕入実績入力", records: errorRows });
        this.$dialog.notify.error(`取込データに入力エラーが存在します。ご確認ください。`, {
          timeout: 2300,
        });
      }
      return isValid;
    },
    onValidGrid(row) {
      var ret = true;
      var messages = [];
      let columnName = "";
      let checkValue;

      this.setValidMessage(this.rules.maxLength(12)(row.propositionDetailId), "委託・消化仕入明細ID", messages);

      // 委託先取引先名
      columnName = "委託先取引先名";
      checkValue = row.customerCode;
      this.setValidMessage(this.rules.required(checkValue), columnName, messages);
      this.setValidMessage(this.rules.maxLength(12)(row.customerCode), "委託先取引先名", messages);
      // 取引先名
      columnName = "取引先名";
      checkValue = row.customerName;
      this.setValidMessage(this.rules.required(checkValue), columnName, messages);
      checkValue = row.customerFlg;
      this.setValidMessage(this.masterChkRules(checkValue), columnName, messages);
      // 委託JANCODE
      columnName = "委託JANCODE";
      checkValue = row.janCode;
      this.setValidMessage(this.rules.required(checkValue), columnName, messages);
      this.setValidMessage(this.rules.isJancode(checkValue), columnName, messages);
      checkValue = row.productFlg;
      this.setValidMessage(this.masterChkRules(checkValue), columnName, messages);
      // 委託数
      columnName = "委託数";
      checkValue = row.entrustQuantity;
      this.setValidMessage(this.rules.required(checkValue), columnName, messages);
      this.setValidMessage(this.rules.isNumber(checkValue), columnName, messages);
      this.setValidMessage(this.rules.maxLength(8)(checkValue), columnName, messages);
      this.setValidMessage(this.rules.isMinNumber(1)(checkValue), columnName, messages);
      // 卸掛率
      columnName = "卸掛率";
      checkValue = row.wholesaleRate;
      this.setValidMessage(this.rules.required(checkValue), columnName, messages);
      this.setValidMessage(this.rules.isDecimal(checkValue), columnName, messages);
      this.setValidMessage(this.rules.isRate(checkValue), columnName, messages);
      this.setValidMessage(this.rules.maxRateLength(3, 1)(row.wholesaleRate), "卸掛率", messages);
      // 仕入掛率
      columnName = "仕入掛率";
      checkValue = row.purchaseRate;
      this.setValidMessage(this.rules.required(checkValue), columnName, messages);
      this.setValidMessage(this.rules.isDecimal(checkValue), columnName, messages);
      this.setValidMessage(this.rules.isRate(checkValue), columnName, messages);
      this.setValidMessage(this.rules.maxRateLength(3, 1)(row.purchaseRate), "仕入掛率", messages);
      // 配送先名
      columnName = "配送先名";
      checkValue = row.deliveryCode;
      this.setValidMessage(this.rules.required(checkValue), columnName, messages);
      checkValue = row.deliveryFlg;
      this.setValidMessage(this.masterChkRules(checkValue), columnName, messages);
      // 配送先名
      columnName = "返送先名";
      checkValue = row.supplierDeliveryFlg;
      this.setValidMessage(this.masterChkRules(checkValue), columnName, messages);
      // 直送依頼
      columnName = "直送依頼";
      checkValue = row.directDeliveryRequestFlg;
      this.setValidMessage(this.rules.required(checkValue), columnName, messages);
      this.setValidMessage(this.FlgChkRules(checkValue), columnName, messages);
      // 在庫所有元
      columnName = "在庫所有元";
      checkValue = row.stockOwner;
      this.setValidMessage(this.rules.required(checkValue), columnName, messages);
      this.setValidMessage(this.StockOwnerChkRules(checkValue), columnName, messages);
      // 出荷予定日
      columnName = "出荷予定日";
      checkValue = row.shipmentPlanDate;
      this.setValidMessage(this.rules.required(checkValue), columnName, messages);
      this.setValidMessage(this.rules.isTodayAfter(checkValue), columnName, messages);

      if (messages.length > 0) ret = messages;

      return ret;
    },
    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
    onComplete(canceled) {
      this.$emit("complete", canceled);
      this.step = 1;
      this.file = null;
    },
    async getCustomerData(value) {
      console.log();
      let customerResponse;
      let customer = this.alreadyCustomerData.find((e) => e.customerCode === value);
      if (customer) {
        return customer;
      }
      try {
        customerResponse = await this.$store.dispatch("customer/search", {
          customerCode: value,
        });
        switch (customerResponse.data?.header.resultCode) {
          case ApiStatus.consts.SUCCESS:
            if (customerResponse.data.contents.customers.length > 0) {
              customer = customerResponse.data.contents.customers[0];
              this.alreadyCustomerData.push(customer);
              return customer;
            }
            return null;
          default:
            return this.redirectError();
        }
      } catch (error) {
        console.error("DetailImport::getCustomerData", error);
        this.apiRequestError(error);
      }
    },
    async getProductData(value) {
      let productResponse;
      if (value >= 8) {
        try {
          productResponse = await this.$store.dispatch("product/search", {
            janCode: value,
          });
          switch (productResponse.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              if (productResponse.data.contents.products.length > 0) {
                const product = productResponse.data.contents.products[0];
                return product;
              }
              return null;
            default:
              return this.redirectError();
          }
        } catch (error) {
          console.error("DetailImport::getProductData", error);
          this.apiRequestError(error);
        }
      }
    },
    async getDeliveryData(value) {
      let deliveryResponse;
      if (value == null || value == "") {
        return null;
      }
      let delivery = this.alreadyDeliveryData.find((e) => e.deliveryCode === value);
      if (delivery) {
        return delivery;
      }
      try {
        deliveryResponse = await this.$store.dispatch("deliveryDestination/searchDelivery", {
          deliveryCode: value,
        });
        switch (deliveryResponse.data?.header.resultCode) {
          case ApiStatus.consts.SUCCESS:
            if (deliveryResponse.data.contents.deliveries.length > 0) {
              delivery = deliveryResponse.data.contents.deliveries[0];
              this.alreadyDeliveryData.push(delivery);
              return delivery;
            }
            return null;
          default:
            return this.redirectError();
        }
      } catch (error) {
        console.error("DetailImport::getDeliveryData", error);
        this.apiRequestError(error);
      }
    },
    masterChkRules(value) {
      if (value == true) return true;
      return "取引マスタに存在しないか、案件に登録した法人の店舗ではありません。";
    },
    FlgChkRules(value) {
      if (value && (value == "Y" || value == "N")) return true;
      return "「Y」か「N」で指定してください";
    },
    StockOwnerChkRules(value) {
      if (value && StockOwners.of(value) != null) return true;
      const message = StockOwners.all().map((value) => {
        return value.value + "(" + value.text + ")";
      });
      return `${message.join("、")}で指定してください`;
    },
    onCancel() {
      this.file = null;
      this.$emit("cancel");
    },
    onBack() {
      this.$refs.importForm.resetValidation();
      this.file = null;
      this.step--;
    },
  },
  beforeMount() {
    if (this.isCafereoUser) this.gridOptions.columnDefs = this.cafereoColumns;
    if (this.isVendorUser) this.gridOptions.columnDefs = this.vendorColumns;
  },
};
</script>
