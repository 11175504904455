<template>
  <v-card>
    <dialog-bar @expand="$emit('expand', $event)"></dialog-bar>
    <v-card-text class="ma-0 pa-0">
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">取り込み入力</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="2">取り込み編集/確認</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="3">完了</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form style="height: 300px" ref="importForm" v-model="validImportForm" lazy-validation>
              <v-container class="pa-0">
                <v-row dense>
                  <v-col cols="12">
                    <v-file-input
                      label="CSVファイルをアップロードしてください"
                      v-model="file"
                      :rules="[rules.required, rules.isCsv]"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <simple-file-drop v-model="file" style="height: 145px"></simple-file-drop>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
            <v-divider class="my-3"></v-divider>
            <v-btn color="secondary" @click="$emit('cancel')">キャンセル</v-btn>
            <v-btn color="primary" class="float-right" @click="onImport()">取り込み</v-btn>
          </v-stepper-content>
          <v-stepper-content step="2">
            <div style="height: 300px">
              <ag-grid-vue
                :gridOptions="gridOptions"
                :rowData="csvRecords"
                class="ag-theme-alpine"
                style="height: 100%"
                :alwaysShowHorizontalScroll="true"
              ></ag-grid-vue>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-btn color="secondary" @click="onBack()">戻る</v-btn>
            <v-btn color="primary" class="float-right" @click="onSubmit()">確定</v-btn>
          </v-stepper-content>
          <v-stepper-content step="3">
            <div style="height: 300px">
              <p>{{ importCount }}件の取り込みが完了しました。</p>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-btn color="primary" class="float-right" @click="onComplete()">閉じる</v-btn></v-stepper-content
          >
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog ref="bulkErrorGrid" width="80%" height="80%" :columns="errorColmuns"></error-grid-dialog>
  </v-card>
</template>

<style>
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import Validation from "../../libs/validation";
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
} from "../../models/ag-grid/columnTypes";
import FileUtils from "../../utils/FileUtils";
import DialogBar from "../common/DialogBar.vue";
import SimpleFileDrop from "../common/SimpleFileDrop.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import { corpList as CorpList } from "../../consts/CorpList";
import ConversionTypes from "./../../consts/ConversionTypes";

export default {
  name: "PropositionResultImport",
  props: ["propositionDetailList", "corporateCode"],
  components: {
    AgGridVue,
    DialogBar,
    SimpleFileDrop,
    ErrorGridDialog,
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  data: () => ({
    rules: {
      required: Validation.required,
      maxLength: Validation.maxLength,
      isDecimal: Validation.isDecimal,
      isNumber: Validation.isNumber,
      isJancode: Validation.isJancode,
      isMinNumber: Validation.isMinNumber,
      isRate: Validation.isRate,
      isTodayAfter: Validation.isTodayAfter,
      isCsv: Validation.isCsv,
      maxRateLength: Validation.maxRateLength,
    },
    validImportForm: null,
    step: 1,
    file: null,
    importCount: 0,
    gridOptions: {
      defaultColDef: {
        resizable: true,
        sortable: false,
        filter: false,
        editable: false,
      },
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
        dpDateColumn: DateColumn,
        dpFullDateColumn: FullDateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
      },
      columnDefs: [],
      rowSelection: false,
      suppressRowClickSelection: true,
      singleClickEdit: true,
      pagination: false,
      localeText: AG_GRID_LOCALE_JA,
    },
    csvRecords: [],
    cafereoColumns: [
      { headerName: "委託・消化仕入明細ID", field: "propositionDetailId" },
      { headerName: "販売実績数", field: "salesResults", type: "dpNumericColumn" },
      { headerName: "返品数", field: "returnQuantity", type: "dpNumericColumn" },
      { headerName: "委託・消化仕入ID", field: "propositionId" },
      { headerName: "案件名", field: "propositionName" },
      { headerName: "委託JANCODE", field: "janCode" },
      { headerName: "委託タイトル", field: "title" },
      { headerName: "委託商品名", field: "productName" },
      {
        headerName: "取引先名",
        field: "customerName",
        width: 300,
      },
      { headerName: "委託数", field: "entrustQuantity", type: "dpNumericColumn" },
      { headerName: "メーカー検品数", field: "makerInspectionQuantity", type: "dpNumericColumn" },
      { headerName: "受注ID", field: "orderId" },
    ],
    errorColmuns: [
      { headerName: "委託・消化仕入明細ID", field: "propositionDetailId" },
      {
        headerName: "エラー内容",
        field: "errorMessage",
        wrapText: true,
        autoHeight: true,
        cellRenderer: function (param) {
          return param.data.errorMessage.join("<br>");
        },
      },
    ],
  }),
  methods: {
    async onImport() {
      if (this.validate()) {
        try {
          this.loadingOn();
          const csvLines = await FileUtils.readAsCsv(this.file);
          this.csvRecords = [];
          if (CorpList.LOFT != this.corporateCode) {
            await csvLines.forEach(async (line, index) => {
              if (index > 0 && line.length > 1) {
                let record = {
                  propositionDetailId: line[0],
                  inputSalesResults: line[1],
                  inputReturnQuantity: line[2],
                  salesNumberFlg: this.rules.isNumber(line[1]),
                  returnNumberFlg: this.rules.isNumber(line[2]),
                  zeroFlg: false,
                  detailFlg: false,
                };

                this.propositionDetailList.forEach((detail) => {
                  if (detail.propositionDetailId == record.propositionDetailId) {
                    record = { ...record, ...detail };
                    record.detailFlg = true;

                    if (record.salesNumberFlg === true) record.salesResults += Number(record.inputSalesResults);
                    if (record.returnNumberFlg === true) record.returnQuantity += Number(record.inputReturnQuantity);

                    if (record.salesNumberFlg === true && record.returnNumberFlg === true)
                      record.zeroFlg = Number(record.inputSalesResults) + Number(record.inputReturnQuantity) == 0;
                  }
                });
                if (!record.detailFlg) {
                  if (record.salesNumberFlg === true) record.salesResults = Number(record.inputSalesResults);
                  if (record.returnNumberFlg === true) record.returnQuantity = Number(record.inputReturnQuantity);

                  if (record.salesNumberFlg === true && record.returnNumberFlg === true)
                    record.zeroFlg = Number(record.inputSalesResults) + Number(record.inputReturnQuantity) == 0;
                }
                this.csvRecords.push(record);
              }
            });
          } else {
            let sumRecord = {
              customerCd: null,
              jancode: null,
              quantity: 0,
            };
            const sumRecords = [];
            // 取引先変換マスタを取得し、店舗CD変換情報を取得
            const conversions = [];
            const response = await this.getConversionData();
            response.forEach((row) => {
              if (row.conversionType == ConversionTypes.CUSTOMER) {
                conversions.push(row);
              }
            });
            console.log("partnerCode:" + conversions[0].partnerCode);
            // 店舗CDとJANCODEでサマリ
            await csvLines.forEach(async (line) => {
              if (line.length > 1) {
                console.log(line[1].replace(/"/g, ""));
                const conversion = conversions.filter((row) => row.partnerCode == line[1].replace(/"/g, ""))[0];
                const customerCd = conversion ? conversion.cafereoCode : "";

                const jancode = line[3].replace(/"/g, "");
                console.log("jancode:" + jancode + ",customerCd:" + customerCd);
                const quantity = Number(line[7]);
                if (sumRecord.customerCd === customerCd && sumRecord.jancode === jancode) {
                  sumRecord.quantity += quantity;
                } else {
                  if (sumRecord.customerCd != null) {
                    sumRecords.push(sumRecord);
                    sumRecord = {
                      customerCd: null,
                      jancode: null,
                      quantity: 0,
                    };
                  }
                  sumRecord.customerCd = customerCd;
                  sumRecord.jancode = jancode;
                  sumRecord.quantity = quantity;
                }
              }
            });
            if (sumRecord.customerCd != null) sumRecords.push(sumRecord);
            console.log(sumRecords);
            this.csvRecords = sumRecords.map((row, index) => {
              let record = {
                inputSalesResults: row.quantity,
                inputReturnQuantity: 0,
                salesNumberFlg: true,
                returnNumberFlg: true,
                zeroFlg: false,
                detailFlg: false,
              };

              this.propositionDetailList.forEach((detail) => {
                if (detail.customerCode == row.customerCd && detail.janCode == row.jancode) {
                  record = { ...record, ...detail };
                  record.detailFlg = true;

                  record.salesResults += record.inputSalesResults;

                  record.zeroFlg = record.inputSalesResults + record.inputReturnQuantity == 0;
                }
              });
              if (!record.detailFlg) {
                record.propositionDetailId = index + 1;
                record.salesResults = record.inputSalesResults;
                record.returnQuantity = record.inputReturnQuantity;
              }

              return record;
            });
          }
          if (this.validateRecords(this.csvRecords)) {
            this.step++;
          }
        } catch (error) {
          this.$dialog.notify.error(`アップロードファイルの読み込みに失敗しました。`, { timeout: 2300 });
        } finally {
          this.loadingOff();
        }
      }
    },
    validate() {
      const isValid = this.$refs.importForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      } else {
        this.$refs.importForm.resetValidation();
      }
      return isValid;
    },
    validateRecords(records) {
      let isValid = true;
      if (records.length === 0) {
        this.$dialog.notify.error(`データがありません`, { timeout: 2300 });
        isValid = false;
      }
      return isValid;
    },
    async onSubmit() {
      if (this.validateRows(this.csvRecords)) {
        try {
          this.loadingOn();
          let updateModels = [];
          this.csvRecords.forEach((row) => {
            updateModels.push({
              propositionDetailId: row.propositionDetailId,
              returnQuantity: row.returnQuantity,
              salesResults: row.salesResults,
              updateDatetime: row.updateDate,
            });
          });
          var requestContents = {
            propositionDetails: updateModels,
          };
          const result = await this.$store.dispatch("propositionDetail/resultEntry", requestContents);

          let error = result.data?.header;

          const errorRows = [];
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  errorRows.push({
                    propositionDetailId: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              if (errorRows.length > 0) {
                this.$refs.bulkErrorGrid.open({ title: "委託・消化仕入実績入力", records: errorRows });
                this.$dialog.notify.error(`委託・消化仕入実績登録に失敗したデータが存在します。ご確認ください。`, {
                  timeout: 2300,
                });
              }
              this.importCount = result.data?.contents.countQuantity;
              this.step++;
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("PropositionResultImport::onUpdateClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    validateRows(rows) {
      let isValid = true;
      let errorRows = [];
      rows.forEach((row) => {
        var error = this.onValidGrid(row);
        if (error != true) {
          errorRows.push({
            propositionDetailId: row.propositionDetailId,
            errorMessage: error,
          });
          isValid = false;
        }
      });
      if (!isValid) {
        this.$refs.bulkErrorGrid.open({ title: "委託・消化仕入実績入力", records: errorRows });
        this.$dialog.notify.error(`取込データに入力エラーが存在します。ご確認ください。`, {
          timeout: 2300,
        });
      }
      return isValid;
    },
    onValidGrid(row) {
      var ret = true;
      var messages = [];
      let columnName = "";
      let checkValue;

      this.setValidMessage(this.rules.required(row.propositionDetailId), "委託・消化仕入明細ID", messages);
      this.setValidMessage(this.rules.maxLength(12)(row.propositionDetailId), "委託・消化仕入明細ID", messages);

      checkValue = row.detailFlg;
      this.setValidMessage(this.detailChkRules(checkValue), columnName, messages);
      checkValue = row.zeroFlg;
      this.setValidMessage(this.zeroChkRules(checkValue), columnName, messages);

      // 返品数
      columnName = "返品数";
      this.setValidMessage(row.returnNumberFlg, columnName, messages);
      checkValue = Number(row.returnQuantity);
      this.setValidMessage(this.rules.required(checkValue), columnName, messages);
      this.setValidMessage(this.rules.isNumber(checkValue), columnName, messages);
      this.setValidMessage(this.rules.maxLength(8)(checkValue), columnName, messages);
      this.setValidMessage(this.rules.isMinNumber(0)(checkValue), columnName, messages);
      // 販売実績数
      columnName = "販売実績数";
      this.setValidMessage(row.salesNumberFlg, columnName, messages);
      checkValue = Number(row.salesResults);
      this.setValidMessage(this.rules.required(checkValue), columnName, messages);
      this.setValidMessage(this.rules.isNumber(checkValue), columnName, messages);
      this.setValidMessage(this.rules.maxLength(8)(checkValue), columnName, messages);
      this.setValidMessage(this.rules.isMinNumber(0)(checkValue), columnName, messages);

      if (messages.length > 0) ret = messages;

      return ret;
    },
    setValidMessage(check, culumnName, messages) {
      let message = (culumnName ? culumnName + "は" : "") + check;
      if (!(check === true)) messages.push(message);
    },
    onComplete(canceled) {
      this.$emit("complete", canceled);
      this.step = 1;
      this.file = null;
    },
    async getConversionData() {
      let conversionResponse;
      try {
        conversionResponse = await this.$store.dispatch("conversion/search", {
          corporateCode: this.corporateCode,
        });
        switch (conversionResponse.data?.header.resultCode) {
          case ApiStatus.consts.SUCCESS:
            if (conversionResponse.data.contents.conversions.length > 0) {
              const conversion = conversionResponse.data.contents.conversions;
              return conversion;
            }
            return [];
          default:
            return this.redirectError();
        }
      } catch (error) {
        console.error("PropositionResultImport::getConversionData", error);
        this.apiRequestError(error);
      }
    },
    detailChkRules(value) {
      if (value == true) return true;
      return "該当する明細データが存在しません";
    },
    zeroChkRules(value) {
      if (value == false) return true;
      return "返品数、販売実績数のどちらかの設定が必要です。";
    },
    onBack() {
      this.$refs.importForm.resetValidation();
      this.file = null;
      this.step--;
    },
  },
  beforeMount() {
    if (this.isCafereoUser) this.gridOptions.columnDefs = this.cafereoColumns;
    if (this.isVendorUser) this.gridOptions.columnDefs = this.vendorColumns;
  },
};
</script>
