<template>
  <v-card>
    <v-card-title>
      <span class="headline" v-if="updateModel.createNew"><v-icon>mdi-account-plus</v-icon>委託・消化仕入明細追加</span>
      <span class="headline" v-if="!updateModel.createNew"
        ><v-icon>mdi-account-edit</v-icon>委託・消化仕入明細編集</span
      >
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="propositionDetailForm" v-model="validEntryForm" lazy-validation>
        <v-container>
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-text-field
                label="委託・消化仕入明細ID"
                v-model="updateModel.propositionDetailId"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-text-field
                label="委託・消化仕入ID"
                v-model="updateModel.propositionId"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                label="案件名"
                item-text="propositionName"
                item-value="propositionId"
                :items="propositionList"
                v-model="updateModel.propositionId"
                filled
                dense
                :rules="[rules.required]"
                @change="onSelectChangedProposition"
                :disabled="updateModel.createNew"
                attach
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <customer-field
                label="取引先名"
                v-model="updateModel.customerCode"
                :code="updateModel.customerCode"
                :corporate="selectedProposition.corporateCode"
                :rules="[rules.required]"
              ></customer-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="委託JANCODE"
                v-model="updateModel.janCode"
                filled
                dense
                @change="ChangeJancode"
                :rules="[rules.required, rules.isJancode]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="委託タイトル"
                v-model="updateModel.title"
                filled
                dense
                disabled
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="委託商品名"
                v-model="updateModel.productName"
                filled
                dense
                disabled
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                label="委託数"
                filled
                dense
                v-model="updateModel.entrustQuantity"
                :disabled="!updateModel.createNew"
                :rules="[rules.required, rules.isNumber, rules.isMinNumber(1)]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                label="卸掛率"
                filled
                dense
                v-model="updateModel.wholesaleRate"
                :rules="[rules.required, rules.isDecimal, rules.isRate, rules.maxRateLength(3, 1)]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                label="仕入掛率"
                filled
                dense
                v-model="updateModel.purchaseRate"
                :rules="[rules.required, rules.isDecimal, rules.isRate, rules.maxRateLength(3, 1)]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6">
              <delivery-field
                ref="delivery"
                :code="updateModel.deliveryCode"
                @selected="onDeliveryChanged"
                :groupType="2"
                :groupCode="updateModel.customerCode"
                disabled-no-data
                v-model="updateModel.deliveryCode"
                :rules="[rules.required]"
              ></delivery-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-switch
                label="直送依頼"
                inset
                dense
                v-model="updateModel.directDeliveryRequestFlg"
                :disabled="disabledDirectDeliveryRequestFlg"
                :rules="[rules.required]"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="4">
              <v-text-field
                label="配送先〒"
                filled
                dense
                disabled
                :value="updateModel.deliveryPostalCode"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field
                label="配送先住所"
                filled
                dense
                disabled
                :value="updateModel.deliveryAddress"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="4">
              <v-text-field label="届先法人名" filled dense disabled :value="updateModel.deliveryName1"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field label=" 届先名称" filled dense disabled :value="updateModel.deliveryName2"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                label="配送先TEL"
                filled
                dense
                disabled
                :value="updateModel.deliveryTelNumber"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-select
                :items="stockOwnerList"
                label="在庫所有元"
                v-model="updateModel.stockOwner"
                filled
                dense
                :rules="[rules.required]"
                @change="ChangeStockOwner"
                attach
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6">
              <delivery-field
                ref="supplierDelivery"
                :code="updateModel.supplierDeliveryCd"
                @selected="onSupplierDeliveryChanged"
                :groupType="2"
                :groupCode="updateModel.supplierCd"
                disabled-no-data
                :disabled="disabledSupplierDeliveryCd"
                v-model="updateModel.supplierDeliveryCd"
                :rules="[rules.requiredSupplierDeliveryCd]"
                label="返送先名"
              ></delivery-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="4">
              <v-text-field
                label="返送先〒"
                filled
                dense
                disabled
                :value="updateModel.supplierDeliveryPostalCode"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field
                label="返送先住所"
                filled
                dense
                disabled
                :value="updateModel.supplierDeliveryAddress"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="4">
              <v-text-field
                label="返送先届先法人名"
                filled
                dense
                disabled
                :value="updateModel.supplierDeliveryName1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                label="返送先届先名称"
                filled
                dense
                disabled
                :value="updateModel.supplierDeliveryName2"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                label="返送先TEL"
                filled
                dense
                disabled
                :value="updateModel.supplierDeliveryTelNumber"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="3">
              <dp-date-picker
                label="出荷予定日"
                v-model="updateModel.shipmentPlanDate"
                filled
                dense
                type="date"
                :rules="[rules.required, rules.isTodayAfter]"
              ></dp-date-picker>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="$emit('onDialogClose')">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onCreateClick" v-if="updateModel.createNew">登録</v-btn>
      <v-btn color="primary" @click="onUpdateClick" v-if="!updateModel.createNew">更新</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import Validation from "../../libs/validation";
import CustomerField from "../common/fields/CustomerField.vue";
import DeliveryField from "../../components/common/fields/DeliveryField.vue";
import StockOwners from "../../consts/StockOwners";

import { statuses as ApiStatus } from "../../libs/api-client";

export default {
  name: "PropositionDetailEntry",
  props: ["inputModel", "inputHeader", "propositionList"],
  components: {
    DeliveryField,
    CustomerField,
  },
  data() {
    return {
      rules: {
        required: Validation.required,
        maxLength: Validation.maxLength,
        isDecimal: Validation.isDecimal,
        isNumber: Validation.isNumber,
        isJancode: Validation.isJancode,
        isMinNumber: Validation.isMinNumber,
        isRate: Validation.isRate,
        maxRateLength: Validation.maxRateLength,
        isTodayAfter: Validation.isTodayAfter,
        requiredSupplierDeliveryCd: this.requiredSupplierDeliveryCd,
      },
      selectedProposition: null,
      validEntryForm: null,
      updateModel: null,
      corporationOffices: [],
      searchingOffices: false,
      stockOwnerList: StockOwners.all(),
      disabledDirectDeliveryRequestFlg: true,
      disabledSupplierDeliveryCd: true,
    };
  },
  beforeMount() {
    this.initUpdateModel(this.inputModel);
  },
  watch: {
    inputModel(inputModel) {
      this.initUpdateModel(inputModel);
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    initUpdateModel(inputModel) {
      this.updateModel = { ...inputModel };
      this.selectedProposition = this.inputHeader;
      if (!this.updateModel.createNew) {
        this.updateModel.stockOwner = Number(this.updateModel.stockOwner);
        this.onDeliveryChanged();
        this.onSupplierDeliveryChanged();
      }
      if (this.updateModel.createNew) {
        this.updateModel.propositionDetailId = "自動採番されます";
        this.updateModel.propositionId = this.inputHeader.propositionId;
        this.updateModel.stockOwner = StockOwners.CAFEREO;
        this.updateModel.directDeliveryRequestFlg = false;
      }
      this.disabledDirectDeliveryRequestFlg = Number(this.updateModel.stockOwner) == StockOwners.CAFEREO;
      this.disabledSupplierDeliveryCd = Number(this.updateModel.stockOwner) == StockOwners.CAFEREO;
    },
    async ChangeJancode() {
      let productResponse;
      this.updateModel.productCode = null;
      this.updateModel.productName = null;
      this.updateModel.title = null;
      this.updateModel.retailPrice = 0;
      this.updateModel.supplierCd = null;
      this.disabledSupplierDeliveryCd = true;
      this.updateModel.supplierDeliveryCd = null;
      this.updateModel.supplierDeliveryPostalCode = null;
      this.updateModel.supplierDeliveryAddress = null;
      this.updateModel.supplierDeliveryName1 = null;
      this.updateModel.supplierDeliveryName2 = null;
      this.updateModel.supplierDeliveryTelNumber = null;
      if (this.updateModel.janCode >= 8) {
        try {
          this.loadingOn();
          productResponse = await this.$store.dispatch("product/search", {
            janCode: this.updateModel.janCode,
          });
          switch (productResponse.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              if (productResponse.data.contents.products.length > 0) {
                const product = productResponse.data.contents.products[0];
                this.updateModel.productCode = product.productCd;
                this.updateModel.productName = product.productName;
                this.updateModel.title = product.title;
                this.updateModel.retailPrice = product.retailPrice;
                this.updateModel.supplierCd = product.supplierCd;
                this.disabledSupplierDeliveryCd = Number(this.updateModel.stockOwner) == StockOwners.CAFEREO;
              }
              this.updateModel.purchasePriceTotal = this.updateModel.unitPrice * this.updateModel.purchaseQuantity;
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("PropositionDetailEntry::onSearchProduct", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    ChangeStockOwner(value) {
      this.disabledSupplierDeliveryCd = false;
      if (value == StockOwners.CAFEREO) {
        this.updateModel.directDeliveryRequestFlg = false;
        this.disabledDirectDeliveryRequestFlg = true;
        if (this.updateModel.janCode) this.disabledSupplierDeliveryCd = true;
        this.updateModel.supplierDeliveryCd = null;
        this.updateModel.supplierDeliveryPostalCode = null;
        this.updateModel.supplierDeliveryAddress = null;
        this.updateModel.supplierDeliveryName1 = null;
        this.updateModel.supplierDeliveryName2 = null;
        this.updateModel.supplierDeliveryTelNumber = null;
      } else {
        this.disabledDirectDeliveryRequestFlg = false;
      }
    },
    requiredSupplierDeliveryCd() {
      if (Number(this.updateModel.stockOwner) == StockOwners.CAFEREO) return true;
      return this.rules.required(this.updateModel.supplierDeliveryCd);
    },
    onSelectChangedProposition(value) {
      if (value) {
        this.selectedProposition = this.propositionList.find((p) => p.propositionId === value);
      }
    },
    getUpdateModel() {
      return this.updateModel;
    },
    onDeliveryChanged(value) {
      this.loadingOn();
      console.log(value);
      const delivery = this.$refs.delivery?.selected();
      console.log(delivery);
      if (delivery) {
        this.updateModel.deliveryPostalCode = delivery.deliveryZipCode;
        this.updateModel.deliveryAddress = delivery.deliveryAddress;
        this.updateModel.deliveryName1 = delivery.delivery1;
        this.updateModel.deliveryName2 = delivery.delivery2;
        this.updateModel.deliveryTelNumber = delivery.deliveryPhoneNumber;
      } else {
        this.updateModel.deliveryPostalCode = null;
        this.updateModel.deliveryAddress = null;
        this.updateModel.deliveryName1 = null;
        this.updateModel.deliveryName2 = null;
        this.updateModel.deliveryTelNumber = null;
      }
      this.loadingOff();
    },
    onSupplierDeliveryChanged() {
      this.loadingOn();
      const delivery = this.$refs.supplierDelivery?.selected();
      if (delivery) {
        this.updateModel.supplierDeliveryPostalCode = delivery.deliveryZipCode;
        this.updateModel.supplierDeliveryAddress = delivery.deliveryAddress;
        this.updateModel.supplierDeliveryName1 = delivery.delivery1;
        this.updateModel.supplierDeliveryName2 = delivery.delivery2;
        this.updateModel.supplierDeliveryTelNumber = delivery.deliveryPhoneNumber;
      } else {
        this.updateModel.supplierDeliveryPostalCode = null;
        this.updateModel.supplierDeliveryAddress = null;
        this.updateModel.supplierDeliveryName1 = null;
        this.updateModel.supplierDeliveryName2 = null;
        this.updateModel.supplierDeliveryTelNumber = null;
      }
      this.loadingOff();
    },
    async onUpdateClick() {
      if (this.validate()) {
        try {
          this.loadingOn();
          const requestContents = {
            propositionDetailId: this.updateModel.propositionDetailId,
            propositionId: this.updateModel.propositionId,
            customerCode: this.updateModel.customerCode,
            productCode: this.updateModel.productCode,
            wholesaleRate: parseFloat(this.updateModel.wholesaleRate),
            purchaseRate: parseFloat(this.updateModel.purchaseRate),
            updateDatetime: this.updateModel.updateDate,
            deliveryCode: this.updateModel.deliveryCode,
            directDeliveryRequestFlg: this.updateModel.directDeliveryRequestFlg,
            stockOwner: this.updateModel.stockOwner?.toString(),
            shipmentPlanDate: this.updateModel.shipmentPlanDate,
            supplierDeliveryCd: this.updateModel.supplierDeliveryCd,
          };
          const result = await this.$store.dispatch("propositionDetail/update", requestContents);
          switch (result.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.$dialog.notify.info(`委託・消化仕入明細を更新しました (${this.updateModel.propositionDetailId})`, {
                timeout: 2300,
              });
              this.$emit("onEditSubmit", result.data.contents.propositionDetail);
              this.initUpdateModel(result.data.contents.propositionDetail);
              break;
            case ApiStatus.consts.ALREADY_CHANGED:
              this.$dialog.warning({
                title: `委託・消化仕入明細編集`,
                text: result.data.header.messages[this.updateModel.propositionDetailId].join(),
                actions: ["OK"],
              });
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("CorporationUpdate::onUpdateClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    async onCreateClick() {
      if (this.validate()) {
        try {
          this.loadingOn();
          const requestContents = {
            propositionDetailId: this.updateModel.propositionDetailId,
            propositionId: this.updateModel.propositionId,
            customerCode: this.updateModel.customerCode,
            productCode: this.updateModel.productCode,
            entrustQuantity: Number(this.updateModel.entrustQuantity),
            wholesaleRate: parseFloat(this.updateModel.wholesaleRate),
            purchaseRate: parseFloat(this.updateModel.purchaseRate),
            deliveryCode: this.updateModel.deliveryCode,
            directDeliveryRequestFlg: this.updateModel.directDeliveryRequestFlg,
            stockOwner: this.updateModel.stockOwner?.toString(),
            shipmentPlanDate: this.updateModel.shipmentPlanDate,
            supplierDeliveryCd: this.updateModel.supplierDeliveryCd,
          };
          const result = await this.$store.dispatch("propositionDetail/entry", requestContents);
          if (ApiStatus.isSystemError(result.data?.header)) {
            return this.redirectError();
          }
          this.$dialog.notify.info(`委託・消化仕入明細を登録しました (${result.data?.contents.propositionDetailId})`, {
            timeout: 2300,
          });
          this.$emit("onEntrySubmit");
        } catch (error) {
          console.error("PropositionEntry::onUpdateClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },

    validate() {
      const isValid = this.$refs.propositionDetailForm.validate();
      if (!isValid) {
        this.$dialog.error({ text: "入力エラーがあります", actions: ["OK"] });
      }
      return isValid;
    },
  },
};
</script>
