<template>
  <v-card>
    <v-card-title>
      <span class="headline" v-if="updateModel.createNew"><v-icon>mdi-account-plus</v-icon>委託・消化仕入追加</span>
      <span class="headline" v-if="!updateModel.createNew"><v-icon>mdi-account-edit</v-icon>委託・消化仕入編集</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="propositionForm" v-model="validEntryForm" lazy-validation>
        <v-container>
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-text-field
                label="案件ID"
                v-model="updateModel.propositionId"
                filled
                dense
                disabled
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="案件名"
                v-model="updateModel.propositionName"
                filled
                dense
                :rules="[rules.required, rules.maxLength(150)]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="updateModel.propositionType"
                :items="propositionTypeList"
                label="発注区分"
                filled
                dense
                :rules="[rules.required]"
                attach
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <corporation-field
                label="法人名"
                :rules="[rules.required]"
                :group="corporationType"
                :value="updateModel.corporateCode"
                v-model="updateModel.corporateCode"
                :disabled="!updateModel.createNew"
              ></corporation-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                :items="invoicePeriodClassList"
                label="請求方式"
                v-model="updateModel.invoicePeriodClass"
                filled
                dense
                :rules="[rules.required]"
                attach
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <dp-date-picker
                label="請求月"
                v-model="updateModel.invoicePeriodMonth"
                type="date"
                filled
                dense
                :rules="[rules.isTodayAfter]"
              ></dp-date-picker>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4">
              <dp-date-picker
                label="納品日"
                v-model="updateModel.termFrom"
                type="date"
                filled
                dense
                :rules="[rules.required, rules.isTodayAfter, rules.termFrom]"
              ></dp-date-picker>
            </v-col>
            <v-col cols="12" sm="4">
              <dp-date-picker
                label="返品予定日"
                v-model="updateModel.termTo"
                type="date"
                filled
                dense
                :rules="[rules.required, rules.isTodayAfter, rules.termTo]"
              ></dp-date-picker>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="$emit('onDialogClose')">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onCreateClick" v-if="updateModel.createNew">登録</v-btn>
      <v-btn color="primary" @click="onUpdateClick" v-if="!updateModel.createNew">更新</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import moment from "moment";
import InvoicePeriods from "../../consts/InvoicePeriods";
import PropositionTypes from "../../consts/PropositionTypes";
import CorporationTypes from "../../consts/CorporationTypes";
import StockOwners from "../../consts/StockOwners";
import CorporationField from "../../components/common/fields/CorporationField.vue";
import Validation from "../../libs/validation";
import { statuses as ApiStatus } from "../../libs/api-client";

export default {
  name: "PropositionEntry",
  props: ["inputModel"],
  components: {
    CorporationField,
  },
  data() {
    return {
      rules: {
        required: Validation.required,
        isTodayAfter: Validation.isTodayAfter,
        maxLength: Validation.maxLength,
        termFrom: (value) => this.termFromRules(value),
        termTo: (value) => this.termToRules(value),
      },
      validEntryForm: null,
      updateModel: null,
      invoicePeriodClassList: InvoicePeriods.all(),
      propositionTypeList: PropositionTypes.all(),
      stockOwners: StockOwners.all(),
      corporations: [],
      corporationType: CorporationTypes.VENDOR,
    };
  },
  beforeMount() {
    this.initUpdateModel(this.inputModel);
  },
  watch: {
    inputModel(inputModel) {
      this.initUpdateModel(inputModel);
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    termFromRules(value) {
      if (value == null || this.updateModel.termTo == null) return true;
      if (moment(value).isSameOrAfter(this.updateModel.termTo)) return "返品予定日より前の日付を指定してください";
      return true;
    },
    termToRules(value) {
      if (value == null || this.updateModel.termFrom == null) return true;
      if (moment(value).isSameOrBefore(this.updateModel.termFrom)) return "納品日より後の日付を指定してください";
      return true;
    },
    initUpdateModel(inputModel) {
      this.updateModel = { ...inputModel };
      if (!this.updateModel.createNew) {
        this.updateModel.propositionType = Number(this.updateModel.propositionType);
        this.updateModel.invoicePeriodClass = Number(this.updateModel.invoicePeriodClass);
        this.updateModel.termFrom = this.updateModel.deliveryDate;
        this.updateModel.termTo = this.updateModel.expectedreturnDate;
      }
      if (this.updateModel.createNew) this.updateModel.propositionId = "自動採番されます";
    },
    getUpdateModel() {
      return this.updateModel;
    },
    async onUpdateClick() {
      if (this.validate()) {
        try {
          this.loadingOn();
          const requestContents = {
            propositionId: this.updateModel.propositionId,
            propositionName: this.updateModel.propositionName,
            propositionType: this.updateModel.propositionType.toString(),
            invoicePeriodClass: this.updateModel.invoicePeriodClass.toString(),
            invoicePeriodMonth: this.updateModel.invoicePeriodMonth,
            termFrom: this.updateModel.termFrom,
            termTo: this.updateModel.termTo,
            stockOwner: null,
            updateDatetime: this.updateModel.updateDate,
          };
          const result = await this.$store.dispatch("proposition/update", requestContents);
          switch (result.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.$dialog.notify.info(`委託・消化仕入を更新しました (${this.updateModel.propositionId})`, {
                timeout: 2300,
              });
              this.$emit("onEditSubmit", result.data.contents.proposition);
              this.initUpdateModel(result.data.contents.proposition);
              break;
            case ApiStatus.consts.ALREADY_CHANGED:
              this.$dialog.warning({
                title: `委託・消化仕入編集`,
                text: result.data.header.messages[this.updateModel.propositionId].join(),
                actions: ["OK"],
              });
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("PropositionEntry::onUpdateClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    async onCreateClick() {
      if (this.validate()) {
        try {
          this.loadingOn();
          const requestContents = {
            propositionId: this.updateModel.propositionId,
            propositionName: this.updateModel.propositionName,
            propositionType: this.updateModel.propositionType.toString(),
            corpName: this.updateModel.corporateCode,
            invoicePeriodClass: this.updateModel.invoicePeriodClass.toString(),
            invoicePeriodMonth: this.updateModel.invoicePeriodMonth,
            termFrom: this.updateModel.termFrom,
            termTo: this.updateModel.termTo,
            stockOwner: null,
          };
          const result = await this.$store.dispatch("proposition/entry", requestContents);
          if (ApiStatus.isSystemError(result.data?.header)) {
            return this.redirectError();
          }
          this.$dialog.notify.info(`委託・消化仕入を登録しました (${result.data?.contents.propositionId})`, {
            timeout: 2300,
          });
          this.$emit("onEntrySubmit");
        } catch (error) {
          console.error("PropositionEntry::onUpdateClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },

    validate() {
      const isValid = this.$refs.propositionForm.validate();
      if (!isValid) {
        this.$dialog.error({ text: "入力エラーがあります", actions: ["OK"] });
      }
      return isValid;
    },
  },
};
</script>
